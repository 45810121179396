import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import showdown from "showdown";

const converter = new showdown.Converter();

export const TestTemplate = ({
  content,
  contentComponent,
  testName,
  synonyms,
  group,
  contains,
  department,
  price,
  preferredSpecimen,
  helmet,
  testingLocation,
  turnAround,
  forms,
  clinicalUpdate,
  microDetails,
  mainLabOnly,
  patientRequestable,
  patientInstructions,
  availability,
  preparation,
  form,
  deliveryAndHandling,
  registrationCode,
  notes,
  waikato,
  tauranga,
  whakatane,
  rotorua,
  taupo,
  alternativeSpecimen,
  aliquotInstructions,
  assayMethod,
  minimumVolume,
  sampleStorage,
  sampleStability,
  interpretations,
  contactInformation,
  referralLocation,
  sendAwayNotes,
  sendAwayVolume,
  sendAwayTransport,
  externalLabWebsite,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light has-text-primary">
              {testName}
            </h1>
            <button
              className="button is-small is-primary"
              onClick={() => {
                window.history.back();
              }}
            >
              ← Return
            </button>
            <hr />
            <h3 className="has-text-primary">Test Information</h3>
            {!!testName ? (
              <>
                <h4 className="info-label">Test Name</h4>
                <p className="info">{testName}</p>
              </>
            ) : (
              ""
            )}
            {!!synonyms ? (
              <>
                <h4 className="info-label">Synonyms</h4>
                <p className="info">{synonyms}</p>
              </>
            ) : (
              ""
            )}
            {!!department ? (
              <>
                <h4 className="info-label">Department</h4>
                <p className="info">{department}</p>
              </>
            ) : (
              ""
            )}
            {!!price ? (
              <>
                <h4 className="info-label">Price</h4>
                <p className="info">{price}</p>
                <h4 className="disclaimer-label">Price Disclaimer</h4>
                <p className="disclaimer">
                  <a
                    href="https://www.pathlab.co.nz/payment"
                    className="has-text-primary"
                  >
                    <strong>
                      Many services are free for eligible patients.
                    </strong>
                  </a>{" "}
                  A minimum charge may apply. Prices listed are for unfunded,
                  individual, requests. Please{" "}
                  <a
                    href="https://www.pathlab.co.nz/contact"
                    className="has-text-primary"
                  >
                    <strong>contact us</strong>
                  </a>{" "}
                  for{" "}
                  <a
                    href="https://www.pathlab.co.nz/commercial-testing"
                    className="has-text-primary"
                  >
                    <strong>commercial testing</strong>
                  </a>
                  .
                </p>
              </>
            ) : (
              ""
            )}
            {!!preferredSpecimen ? (
              <>
                <h4 className="info-label">Preferred Specimen</h4>
                <p className="info">{preferredSpecimen}</p>
              </>
            ) : (
              ""
            )}
            {!!testingLocation ? (
              <>
                <h4 className="info-label">Testing Location</h4>
                <p className="info">{testingLocation}</p>
              </>
            ) : (
              ""
            )}
            {!!turnAround ? (
              <>
                <h4 className="info-label">Turn Around Time</h4>
                <p className="info">{turnAround}</p>
              </>
            ) : (
              ""
            )}
            {!!forms ? (
              <>
                <h4 className="info-label">Forms</h4>
                <a
                  className="info has-text-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={forms.publicURL}
                >
                  View {forms.name}
                </a>
              </>
            ) : (
              ""
            )}
            {!!clinicalUpdate ? (
              <>
                <h4 className="info-label">Clinical Update</h4>
                <a
                  className="info has-text-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={clinicalUpdate}
                >
                  View Clinical Update
                </a>
              </>
            ) : (
              ""
            )}
            {!!microDetails ? (
              <>
                <h4 className="info-label">Microbiology Clinical Details</h4>
                <a
                  className="info has-text-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.pathlab.co.nz/static/06baaf7166ee9800d24e98f2caa92743/microbiology-clinical-details-guide_v6_june2024.pdf"
                >
                  View Microbiology Clinical Details Guide
                </a>
              </>
            ) : (
              ""
            )}

            {!mainLabOnly &&
            !patientRequestable &&
            !patientInstructions &&
            !availability &&
            !preparation &&
            !form &&
            !deliveryAndHandling ? (
              ""
            ) : (
              <>
                <hr />
                <h3 className="has-text-primary">Collection Information</h3>
              </>
            )}
            {!!mainLabOnly ? (
              <>
                <h4 className="info-label">Main Lab Only</h4>
                <p className="info">{!!mainLabOnly ? "Yes" : "No"}</p>
              </>
            ) : (
              ""
            )}
            {!!patientRequestable ? (
              <>
                <h4 className="info-label">Patient Requestable</h4>
                <p className="info">{!!patientRequestable ? "Yes" : "No"}</p>
              </>
            ) : (
              ""
            )}
            {!!patientInstructions ? (
              <>
                <h4 className="info-label">Patient Instructions</h4>
                <div className="info">
                  <a
                    className="has-text-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={patientInstructions}
                  >
                    View Patient Instructions
                  </a>
                </div>
              </>
            ) : (
              ""
            )}
            {!!availability ? (
              <>
                <h4 className="info-label">Availability</h4>
                <p className="info">{availability}</p>
              </>
            ) : (
              ""
            )}
            {!!preparation ? (
              <>
                <h4 className="info-label">Preparation</h4>
                <div
                  className="info"
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(preparation),
                  }}
                />
              </>
            ) : (
              ""
            )}
            {!!form ? (
              <>
                <h4 className="info-label">Form</h4>
                <p className="info">{form}</p>
              </>
            ) : (
              ""
            )}
            {!!deliveryAndHandling ? (
              <>
                <h4 className="info-label">Delivery and Handling</h4>
                <p className="info">{deliveryAndHandling}</p>
              </>
            ) : (
              ""
            )}
            <hr />
            <h3 className="has-text-primary">Processing Information</h3>
            {!!registrationCode ? (
              <>
                <h4 className="info-label">Registration Code</h4>
                <p className="info">{registrationCode}</p>
              </>
            ) : (
              ""
            )}
            {!!group ? (
              <>
                <h4 className="info-label">Part of Group</h4>
                <p className="info">{group}</p>
              </>
            ) : (
              ""
            )}
            {!!contains ? (
              <>
                <h4 className="info-label">Group Contains</h4>
                <p className="info">{contains}</p>
              </>
            ) : (
              ""
            )}
            {!!notes ? (
              <>
                <h4 className="info-label">Notes</h4>
                <div
                  className="info"
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(notes),
                  }}
                />
              </>
            ) : (
              ""
            )}
            {!waikato && !tauranga && !whakatane && !rotorua && !taupo ? (
              ""
            ) : (
              <h4 className="has-text-primary">Site Specific Notes</h4>
            )}
            {!!waikato ? (
              <>
                <h4 className="info-label">- Waikato</h4>
                <p className="info">{waikato}</p>
              </>
            ) : (
              ""
            )}
            {!!tauranga ? (
              <>
                <h4 className="info-label">- Tauranga</h4>
                <p className="info">{tauranga}</p>
              </>
            ) : (
              ""
            )}
            {!!whakatane ? (
              <>
                <h4 className="info-label">- Whakatane</h4>
                <p className="info">{whakatane}</p>
              </>
            ) : (
              ""
            )}
            {!!rotorua ? (
              <>
                <h4 className="info-label">- Rotorua</h4>
                <p className="info">{rotorua}</p>
              </>
            ) : (
              ""
            )}
            {!!taupo ? (
              <>
                <h4 className="info-label">- Taupo</h4>
                <p className="info">{taupo}</p>
              </>
            ) : (
              ""
            )}
            {!!alternativeSpecimen ? (
              <>
                <h4 className="info-label">Alternative Specimen</h4>
                <p className="info">{alternativeSpecimen}</p>
              </>
            ) : (
              ""
            )}
            {!!aliquotInstructions ? (
              <>
                <h4 className="info-label">Aliquot Instructions</h4>
                <p className="info">{aliquotInstructions}</p>
              </>
            ) : (
              ""
            )}
            {/*&&
            !contactInformation*/}
            {!assayMethod &&
            !minimumVolume &&
            !sampleStorage &&
            !sampleStability &&
            !interpretations ? (
              ""
            ) : (
              <>
                <hr />
                <h3 className="has-text-primary">Methodology</h3>
              </>
            )}
            {!!assayMethod ? (
              <>
                <h4 className="info-label">Assay Method</h4>
                <p className="info">{assayMethod}</p>
              </>
            ) : (
              ""
            )}
            {!!minimumVolume ? (
              <>
                <h4 className="info-label">Minimum Volume</h4>
                <p className="info">{minimumVolume}</p>
              </>
            ) : (
              ""
            )}
            {!!sampleStorage ? (
              <>
                <h4 className="info-label">Sample Storage</h4>
                <p className="info">{sampleStorage}</p>
              </>
            ) : (
              ""
            )}
            {!!sampleStability ? (
              <>
                <h4 className="info-label">Sample Stability</h4>
                <p className="info">{sampleStability}</p>
              </>
            ) : (
              ""
            )}
            {!!interpretations ? (
              <>
                <h4 className="info-label">Interpretations</h4>
                <a
                  className="info has-text-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={interpretations}
                >
                  View Interpretations
                </a>
              </>
            ) : (
              ""
            )}
            {/* {!!contactInformation ? (
              <>
                <h4 className="info-label">Contact Information</h4>
                <a
                  className="info has-text-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"mailto:" + contactInformation}
                >
                  {contactInformation}
                </a>
              </>
            ) : (
              ""
            )} */}

            {!!referralLocation ? (
              <>
                <hr />
                <h3 className="has-text-primary">
                  External Referral Information
                </h3>
              </>
            ) : (
              ""
            )}
            {!!referralLocation ? (
              <>
                <h4 className="info-label">Referral Location</h4>
                <p className="info">{referralLocation}</p>
              </>
            ) : (
              ""
            )}
            {!!sendAwayNotes ? (
              <>
                <h4 className="info-label">Send Away Notes</h4>
                <p className="info">{sendAwayNotes}</p>
              </>
            ) : (
              ""
            )}
            {!!sendAwayVolume ? (
              <>
                <h4 className="info-label">Send Away Volume</h4>
                <p className="info">{sendAwayVolume}</p>
              </>
            ) : (
              ""
            )}
            {!!sendAwayTransport ? (
              <>
                <h4 className="info-label">Send Away Transport</h4>
                <p className="info">{sendAwayTransport}</p>
              </>
            ) : (
              ""
            )}
            {!!externalLabWebsite ? (
              <>
                <h4 className="info-label">External Lab Website</h4>
                <div className="info">
                  <a
                    className="has-text-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={externalLabWebsite}
                  >
                    {externalLabWebsite}
                  </a>
                </div>
              </>
            ) : (
              ""
            )}
            <hr />
            <br />
            <PostContent content={content} />
            <br />
            <button
              className="button is-small is-primary"
              onClick={() => {
                window.history.back();
              }}
            >
              ← Return
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

TestTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  synonyms: PropTypes.string,
  group: PropTypes.string,
  contains: PropTypes.string,
  department: PropTypes.string,
  price: PropTypes.string,
  preferredSpecimen: PropTypes.string,
  testName: PropTypes.string,
  helmet: PropTypes.object,
  testingLocation: PropTypes.string,
  turnAround: PropTypes.string,
  forms: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  clinicalUpdate: PropTypes.string,
  microDetails: PropTypes.bool,
  mainLabOnly: PropTypes.bool,
  patientRequestable: PropTypes.bool,
  patientInstructions: PropTypes.string,
  availability: PropTypes.string,
  preparation: PropTypes.string,
  form: PropTypes.string,
  deliveryAndHandling: PropTypes.string,
  registrationCode: PropTypes.string,
  notes: PropTypes.string,
  waikato: PropTypes.string,
  tauranga: PropTypes.string,
  whakatane: PropTypes.string,
  rotorua: PropTypes.string,
  taupo: PropTypes.string,
  alternativeSpecimen: PropTypes.string,
  aliquotInstructions: PropTypes.string,
  assayMethod: PropTypes.string,
  minimumVolume: PropTypes.string,
  sampleStorage: PropTypes.string,
  sampleStability: PropTypes.string,
  interpretations: PropTypes.string,
  contactInformation: PropTypes.string,
  referralLocation: PropTypes.string,
  sendAwayNotes: PropTypes.string,
  sendAwayVolume: PropTypes.string,
  sendAwayTransport: PropTypes.string,
  externalLabWebsite: PropTypes.string,
};

const Test = ({ data }) => {
  const { markdownRemark: test } = data;

  return (
    <Layout>
      <TestTemplate
        helmet={
          <Helmet titleTemplate="%s | Test">
            <title>{`${test.frontmatter.testName}`}</title>
            <meta
              name="description"
              content={
                "Information about " +
                `${test.frontmatter.testName}` +
                " testing at Pathlab."
              }
            />
          </Helmet>
        }
        content={test.html}
        contentComponent={HTMLContent}
        description={test.frontmatter.testName}
        testName={test.frontmatter.testName}
        synonyms={test.frontmatter.synonyms}
        group={test.frontmatter.group}
        contains={test.frontmatter.contains}
        department={test.frontmatter.department}
        price={test.frontmatter.price}
        preferredSpecimen={test.frontmatter.preferredSpecimen}
        testingLocation={test.frontmatter.testingLocation}
        turnAround={test.frontmatter.turnAround}
        forms={test.frontmatter.forms}
        clinicalUpdate={test.frontmatter.clinicalUpdate}
        microDetails={test.frontmatter.microDetails}
        mainLabOnly={test.frontmatter.mainLabOnly}
        patientRequestable={test.frontmatter.patientRequestable}
        patientInstructions={test.frontmatter.patientInstructions}
        availability={test.frontmatter.availability}
        preparation={test.frontmatter.preparation}
        form={test.frontmatter.form}
        deliveryAndHandling={test.frontmatter.deliveryAndHandling}
        registrationCode={test.frontmatter.registrationCode}
        notes={test.frontmatter.notes}
        waikato={test.frontmatter.waikato}
        tauranga={test.frontmatter.tauranga}
        whakatane={test.frontmatter.whakatane}
        rotorua={test.frontmatter.rotorua}
        taupo={test.frontmatter.taupo}
        alternativeSpecimen={test.frontmatter.alternativeSpecimen}
        aliquotInstructions={test.frontmatter.aliquotInstructions}
        assayMethod={test.frontmatter.assayMethod}
        minimumVolume={test.frontmatter.minimumVolume}
        sampleStorage={test.frontmatter.sampleStorage}
        sampleStability={test.frontmatter.sampleStability}
        interpretations={test.frontmatter.interpretations}
        contactInformation={test.frontmatter.contactInformation}
        referralLocation={test.frontmatter.referralLocation}
        sendAwayNotes={test.frontmatter.sendAwayNotes}
        sendAwayVolume={test.frontmatter.sendAwayVolume}
        sendAwayTransport={test.frontmatter.sendAwayTransport}
        externalLabWebsite={test.frontmatter.externalLabWebsite}
      />
    </Layout>
  );
};

Test.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Test;

export const pageQuery = graphql`
  query TestByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        testName
        synonyms
        group
        contains
        department
        price
        preferredSpecimen
        testingLocation
        turnAround
        forms {
          publicURL
          name
        }
        clinicalUpdate
        microDetails
        mainLabOnly
        patientRequestable
        patientInstructions
        availability
        preparation
        form
        deliveryAndHandling
        registrationCode
        notes
        waikato
        tauranga
        whakatane
        rotorua
        taupo
        alternativeSpecimen
        aliquotInstructions
        assayMethod
        minimumVolume
        sampleStorage
        sampleStability
        interpretations
        contactInformation
        referralLocation
        sendAwayNotes
        sendAwayVolume
        sendAwayTransport
        externalLabWebsite
      }
    }
  }
`;
